import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/components/HeroSection';
import {
    COMMON_BOTTOM_BANNER_CONTENTS,
    GUEST_BG_CHECKS,
    GUEST_VERIFICATION_ADVANTAGES,
    GUEST_VERIFICATION_FAQ,
    GUEST_VERIFICATION_PAGE_ANIMATED_TEXT,
    GUEST_VERIFICATION_PAGE_HEADING,
    GUEST_VERIFICATION_PAGE_SUB_HEADING,
    GUEST_VERIFICATION_RESOURCES,
    RENTAL_SOLUTIONS,
} from '../../sections/solutions/utilities';
import HERO_IMAGE from '../../images/product-assets/guest-verification-hero-image.svg';
import Advantages from '../../sections/solutions/components/Advantages';
import ExpertSolutions from '../../sections/solutions/components/ExpertSolutions';
import IntegrationBanner from '../../sections/solutions/guest-verification/IntegrationBanner';
import Verifications from '../../sections/solutions/guest-verification/Verifications';
import Resources from '../../components/reusable/Resources';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import { useMediaQuery } from '@mui/material';
import VerificationsMobile from '../../sections/solutions/guest-verification/VerificationsMobile';
import BottomBanner from '../../components/reusable/BottomBanner';
import Vendors from '../../components/reusable/vendors/Index';
import MetaTagsProvider from '../../components/MetaTagsProvider';
const GuestVerification = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    return (
        <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=guest_verification&utm_campaign=navbar">
            <MetaTagsProvider
                title="Authenticate: Guest Verification & Screening Solutions"
                description="Secure your rentals with Authenticate's advanced guest verification and screening solutions. Fight chargebacks, prevent fraud, and maximize revenue with instant background checks and automated identity verification."
                keywords="Guest Verification, Guest Screening, Identity Verification, Fraud Prevention, Automated Screening, Rental Protection, Risk Assessment, Short-term Rental Security, Secure Booking Process, Property Protection, "
                path="solutions/guest-verification"
                ogTitle="Authenticate: Guest Verification & Screening Solutions"
                ogDescription="Enhance your rental property's security with Authenticate's guest verification and screening solutions. Our fast, automated identity verification prevents fraudulent bookings and ensures safe, seamless guest onboarding."
                twitterDescription="Protect your rentals with Authenticate's advanced guest verification and screening. Prevent fraud with fast identity verification and risk assessment. Ensure secure bookings and peace of mind."
                ogImage="https://cdn.authenticating.com/public/website/product_pages/guest-verification-og-img.png"
            />
            <HeroSection
                pageTitle="Guest Verification"
                textToAnimate={GUEST_VERIFICATION_PAGE_ANIMATED_TEXT}
                heading={GUEST_VERIFICATION_PAGE_HEADING}
                subHeading={GUEST_VERIFICATION_PAGE_SUB_HEADING}
                heroImage={HERO_IMAGE}
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=guest_verification&utm_campaign=hero"
            />
            <Vendors />
            <Advantages content={GUEST_VERIFICATION_ADVANTAGES} />
            <ExpertSolutions
                heading="Why Authenticate?"
                data1={RENTAL_SOLUTIONS}
                data2={GUEST_BG_CHECKS}
                link1="/products/identity"
                link2="/products/criminal-background-checks"
            />
            <IntegrationBanner />
            {isMobile ? <VerificationsMobile /> : <Verifications />}
            <Resources item={GUEST_VERIFICATION_RESOURCES} />
            <ProductFaq
                titleColor="#262626"
                subHeading="Everything you need to know about Guest Verifications."
                faqData={GUEST_VERIFICATION_FAQ}
            />
            <BottomBanner
                data={COMMON_BOTTOM_BANNER_CONTENTS}
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=guest_verification&utm_campaign=footer"
            />
        </RootLayout>
    );
};

export default GuestVerification;
