import React from 'react';
import styled from 'styled-components';
import { VERIFICATION_PROCESS } from '../utilities';

const Wrapper = styled.section`
    margin-top: 54px;
    background-color: #181717;
    height: 100%;
`;

const Content = styled.div`
    padding: 36px 16px;
`;

const HeadingContainer = styled.div`
    text-align: center;
`;

const Heading = styled.h1`
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;

    span {
        background: linear-gradient(90deg, #ffffff 0%, #91ccff 79%, #0088ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-style: italic;
    }
`;

const Main = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Box = styled.div`
    border: 1px solid #1d2939;
    border-radius: 12px;
    padding: 12px;
    background-color: #121212;
`;

const Image = styled.img`
    max-height: 214px;
    width: 100%;
    object-fit: cover;
    border-radius: 6.58px;
    overflow: hidden;
`;

const Data = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;

const Icon = styled.div`
    border: 1px solid #475467;
    border-radius: 4px;
    width: max-content;

    img {
        filter: invert(1);
        height: 32px;
        width: 32px;
        object-fit: cover;
        padding: 6px;
    }
`;

const Title = styled.h3`
    color: #fff;
    font-size: 14px;
    line-height: 16.59px;
    margin: 12px 0 4px 0;
`;

const Desc = styled.p`
    color: #b3b3b3;
    font-size: 12px;
    line-height: 16.8px;
`;
const VerificationsMobile = () => {
    return (
        <Wrapper>
            <Content>
                <HeadingContainer>
                    <Heading>No PMS? No Problem!</Heading>
                    <Heading>Perform seamless Guest</Heading>
                    <Heading>
                        Verification in <span>30 seconds!</span>
                    </Heading>
                </HeadingContainer>
                <Main>
                    {VERIFICATION_PROCESS.map((item, index) => (
                        <Box key={window.crypto.randomUUID()}>
                            <Image src={item.image} alt={item.title} />
                            <Data>
                                <Icon>
                                    <img src={item.icon} alt={item.title} />
                                </Icon>
                                <Title>{item.title}</Title>
                                <Desc>{item.subText}</Desc>
                            </Data>
                        </Box>
                    ))}
                </Main>
            </Content>
        </Wrapper>
    );
};

export default VerificationsMobile;
